@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.process-block {

	@include mixins.sectionStyles;
	
	.bnpp-container {
		display: flex;
		gap: var(--wp--preset--spacing--60);
		flex-wrap: wrap;

		@media only screen and (max-width: bp.$breakpoint-tiny) {
			gap: 0;
		}

		.process-block__title {
			flex: 0 1 33%;

			@media only screen and (max-width: bp.$breakpoint-small) {
				flex: 1 1 33%;
				margin-bottom: var(--wp--preset--spacing--40);
			}
		}

		.process-block__content {
			flex: 1 1 300px;
		}
	}

	&.contained {

		.bnpp-container {
			padding: var(--wp--preset--spacing--60);
		}

	}

	.process-block__list {
		@include mixins.grid(250px);
		margin-top: var(--wp--preset--spacing--40);
	}


}